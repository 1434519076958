//Angular
import { FlexLayoutModule } from "@angular/flex-layout";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";

// 3rd Party dependencies
import { AuthHttpInterceptor, AuthModule } from "@auth0/auth0-angular";
import {ZonarOwnerIdInterceptor, ZonarUiAuthModule, ZonarUiPermissionsModule} from "@zonar-ui/auth";

//Zonar Pattern Library
import { ZonarUICoreModule } from "@zonar-ui/core";
import { ZonarUITableModule } from "@zonar-ui/table";
import { SidenavModule } from "@zonar-ui/sidenav";
import { TranslateModule, ZonarUiI18nModule } from "@zonar-ui/i18n";
import { ZonarUiProgressSpinnerModule } from "@zonar-ui/progress-spinner";
import { MatIconModule } from "@angular/material/icon";
// App-level Imports
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { environment } from "src/environments/environment";
import { CoreModule } from "src/app/core/core.module";
import { FilterTableComponent } from "./components/filter-table/filter-table.component";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    CoreModule,
    FlexLayoutModule,
    AppRoutingModule,
    HttpClientModule,
    MatIconModule,
    BrowserAnimationsModule,
    FilterTableComponent,
    SidenavModule,
    AuthModule.forRoot({
      audience: environment.auth.audience,
      clientId: environment.auth.clientID,
      domain: environment.auth.domain,
      redirect_uri: window.location.origin,
      scope: "openid profile email",
      max_age: 36000,
      useRefreshTokens: true,
      cacheLocation: "localstorage",
      httpInterceptor: {
        allowedList: [
          `${environment.apiBase.url}/*`,
        ],
      },
    }),
    ZonarUiAuthModule,
    ZonarUICoreModule,
    ZonarUITableModule,
    TranslateModule.forRoot(),
    ZonarUiI18nModule,
    ZonarUiProgressSpinnerModule,
    SidenavModule.forRoot({ stage: environment?.authEnv || null }),
  ],
  providers: [
    {
      provide: "logoutReturnToUrl",
      useValue: window.origin,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ZonarOwnerIdInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
