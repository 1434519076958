import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  AfterViewInit,
  ViewContainerRef,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatSelectModule } from "@angular/material/select";
import {
  ZonarUITableModule,
  ZonarUITableModel,
  ZonarUITableCellType,
} from "@zonar-ui/table";
import { ZonarUiIconModule } from "@zonar-ui/icon";
import { FilterTableComponent } from "../filter-table/filter-table.component";
import { BarChartComponent } from "../bar-chart/bar-chart.component";
import { DriverGameApiService } from "@app/services/driver-game-api.service";
import { TemplatePortal } from "@angular/cdk/portal";
import { GetDriverScoreParams } from "@app/core/models/driver-score.model";
import {
  mockDayData,
  mockWeekData,
} from "@app/services/mocks/mock-driver-scores";

interface Company {
  value: string;
  name: string;
}

@Component({
  selector: "app-main-table",
  standalone: true,
  imports: [
    CommonModule,
    ZonarUITableModule,
    FilterTableComponent,
    MatButtonToggleModule,
    MatSelectModule,
    ZonarUiIconModule,
    MatTooltipModule,
    BarChartComponent,
  ],
  templateUrl: "./main-table.component.html",
  styleUrls: ["./main-table.component.scss"],
})
export class MainTableComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild("scoreDetails") customTemplate: TemplateRef<any>;
  selectedType: string = "table";
  chosenTime: string = "week";
  chosenCompany: string = "knr1101";
  showFirstTable = true;
  dataSource: any[];
  companies: Company[] = [
    { value: "knr1101", name: "Knight Refrigerated" },
    { value: "kni1101", name: "Knight" },
  ];
  searchParams: GetDriverScoreParams = {
    startTime: "2023-04-26",
    endTime: "2023-05-03",
    companyId: "ecba6d94-7f4c-4408-ad7c-9beac51e0454",
  };
  public columnStyles = {
    driverName: { "justify-content": "center" },
  };
  public customCellPortals: Record<string, TemplatePortal<any>>;
  public columns: ZonarUITableModel[] = [
    {
      columnDef: "place",
      header: "Rank",
      cell: (row: any) => row.place,
      sortable: true,
    },
    {
      columnDef: "driverName",
      header: "Driver",
      sortable: true,
      cell: (row: any) => row.driverName,
    },
    {
      columnDef: "index",
      header: "Score",
      sortable: true,
      cell: (row: any) => row.scores.index,
    },
    {
      columnDef: "scoreDetails",
      type: ZonarUITableCellType.Portal,
      header: "Score Info",
    },
  ];
  constructor(
    readonly apiService: DriverGameApiService,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const params = {
      companyId: this.chosenCompany,
    };
    this.getHighScores(params);
  }

  ngOnInit(): void {
    if (this.chosenCompany) {
      const params = {
        companyId: this.chosenCompany,
      };
      this.getHighScores(params);
    }
    // this.apiService.getDriverScores(params).subscribe((scores) => {
    //   if (scores) {
    //     for (let i = 0; i < scores.length; i++) {
    //       scores[i]["place"] = i + 1;
    //     }
    //   }
    //   this.dataSource = scores;
    // });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.customCellPortals = {
        scoreDetails: new TemplatePortal(
          this.customTemplate,
          this.viewContainerRef
        ),
      };
    });
  }
  // this should be having params passed in
  getHighScores(searchParams: GetDriverScoreParams): any {
    const params = {
      companyId: this.chosenCompany,
    };
    this.apiService.getDriverScores(params).subscribe((scores) => {
      if (scores) {
        for (let i = 0; i < scores.length; i++) {
          scores[i]["place"] = i + 1;
        }
      }
      this.dataSource = scores;
    });
    this.showFirstTable = !this.showFirstTable;
    // console.log(params);
    // this.dataSource = mockDayData.scores;
  }

  getScore(row): string {
    return row.scores.index;
  }
  getToolTipText(row): string {
    if (row) {
      const violations = row.scores.violations;
      const miles = row.scores.miles;
      const hours = row.scores.hours;
      const toolTipText = `Violations: ${violations}, Hours: ${hours}, miles: ${miles}`;
      return toolTipText;
    }
  }
  onChange(event): void {
    this.selectedType = event;
  }
  getSelectedTime(time) {
    this.chosenTime = time;
  }
  getCompany(event) {
    const params = {
      companyId: this.chosenCompany,
    };
    this.chosenCompany = event;
    this.getHighScores(params);
  }
}
