export enum DriverScoreSortField {
  DRIVER = 'driver',
  SCORE = 'score'
}

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc'
}

export enum DriverGameApiOperation {
  "DRIVER_SCORE" = 'driver-score'
}
export interface GetDriverScoreParams {
  companyId?: string;
  startTime?: string;
  endTime?: string;
  sortBy?: DriverScoreSortField;
  sortDir?: SortDirection;
  driverIds?: string[];
  operation?: DriverGameApiOperation,
  apiKey?: string
}

export interface ScoreData {
  index: number;
  violations: number;
  miles: number;
  hours: number;
}
export interface DriverScore {
  driverId: string;
  driverName: string;
  divisionId: string;
  scores: ScoreData;
}

export interface DriverScoresResponse {
  scores: DriverScore[];
}