<mat-form-field appearance="fill">
  <mat-label>Company</mat-label>
  <mat-select (selectionChange)="getCompany($event.value)">
    <mat-option *ngFor="let company of companies" [value]="company.value">
      {{ company.name }}
    </mat-option>
  </mat-select>
</mat-form-field>
<div class="table-graph-toggle">
  <mat-button-toggle-group
    (change)="onChange($event.value)"
    name="fontStyle"
    aria-label="Font Style"
  >
    <mat-button-toggle value="chart">Pictures</mat-button-toggle>
    <mat-button-toggle checked value="table">Numbers</mat-button-toggle>
  </mat-button-toggle-group>
</div>
<!-- <app-filter-table
  (selectDayOrWeek)="getSelectedTime($event)"
  [getHighScores]="getHighScores"
></app-filter-table> -->

<ng-container [ngSwitch]="selectedType">
  <div *ngSwitchCase="'table'">
    <div *ngIf="dataSource" class="scores-table">
      <h1>High Scores!</h1>
      <div *ngIf="showFirstTable === true">
        <zui-table
          [columns]="columns"
          [dataSource]="dataSource"
          [columnStyleConfig]="columnStyles"
          [customCellPortals]="customCellPortals"
        >
        </zui-table>
      </div>
      <div *ngIf="showFirstTable === false"></div>
      <zui-table
        [columns]="columns"
        [dataSource]="dataSource"
        [columnStyleConfig]="columnStyles"
        [customCellPortals]="customCellPortals"
      >
      </zui-table>
    </div>
  </div>
  <div *ngSwitchCase="'chart'">
    <app-bar-chart [dataSource]="dataSource"></app-bar-chart>
  </div>
</ng-container>

<ng-template #scoreDetails let-row="row" let-column="column">
  <zui-icon
    class="tooltip-icon"
    icon="info_outline"
    [matTooltip]="getToolTipText(row)"
    matTooltipPosition="right"
  >
  </zui-icon>
</ng-template>
