import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "@auth0/auth0-angular";
import { AppComponent } from "src/app/app.component";
import { LandingPageComponent } from "./components/landing-page/landing-page.component";
import { MainTableComponent } from "./components/main-table/main-table.component";

const routes: Routes = [
  {
    path: "",
    component: LandingPageComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "MainTableComponent",
    component: MainTableComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
