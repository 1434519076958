import { Component, Input, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Chart } from "chart.js/auto";

@Component({
  selector: "app-bar-chart",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./bar-chart.component.html",
  styleUrls: ["./bar-chart.component.scss"],
})
export class BarChartComponent implements OnInit {
  @Input() dataSource;
  public chart: any;

  constructor() {}

  ngOnInit(): void {
    this.createChart();
  }

  getDriverScores(dataSource): Array<any> {
    const scores = [];
    for (let i = 0; i < dataSource.length; i++) {
      scores.push(dataSource[i].scores.index);
    }
    return scores;
  }

  createChart() {
    let driverNames = this.dataSource
      .slice(0, 10)
      .map((driver) => driver.driverName);
    let driverScores = this.getDriverScores(this.dataSource);
    this.chart = new Chart("MyChart", {
      type: "bar", //this denotes tha type of chart
      data: {
        // values on X-Axis
        labels: driverNames,
        datasets: [
          {
            label: "Winner!!",
            data: driverScores,
            backgroundColor: [
              "gold",
              "silver",
              "brown",
              "blue",
              "blue",
              "blue",
              "blue",
              "blue",
              "blue",
              "blue",
            ],
          },
        ],
      },
      options: {
        aspectRatio: 2.5,
        indexAxis: "y",
      },
    });
  }
}
