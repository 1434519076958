import { Injectable } from "@angular/core";
import { datadogRum } from "@datadog/browser-rum";
import { environment } from "src/environments/environment";
import { appName } from "src/environments/shared";

@Injectable({
  providedIn: "root",
})
export class DataDogService {
  constructor() {
    datadogRum.init({
      applicationId: environment.datadog.applicationId,
      clientToken: environment.datadog.clientToken,
      env: environment.authEnv,
      service: appName,
    });
  }
}
