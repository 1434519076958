export const environment = {
  production: true,
  appUrl: "https://your_production_url/",
  authEnv: "production",
  name: "production-na",
  region: "NA",
  apiBase: {
    url: 'https://api-dev.zonarsystems.net',
    key: '26db1063-3a9f-4326-b32b-f2c1afee22cb',
    mock: true
  },
  // TODO: Setup your application's authentication variables: see
  // https://gitlab.com/ZonarSystems/tools/pattern-library/-/tree/master/projects/zonar-ui-auth
  // for instructions on how to do that.
  auth: {
    clientID: "provide_client_id",
    domain: "zonar-login.auth0.com",
    audience: "https://api.zonarsystems.net/",
    // These come from the Core APIs as your application is built.
    applicationId: "provide-local-app-uuid-from-core-api",
    defaultZonarRole: "provide-local-app-uuid-from-core-api",
    useRefreshTokens: true,
  },
  //TODO: Setup your application's datadog app (see empty box readme)
  datadog: {
    applicationId: "application ID",
    clientToken: "Client Token",
  },
  i18n: {
    supportedLanguages: [
      "en",
      "en-US",
      "de-DE",
      "en-GB",
      "it",
      "fr-FR",
      "de",
      "es-ES",
    ],
    defaultLanguage: "en-US",
  },
};
