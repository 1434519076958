<div layout="column" class="landing-page-container">

<div class="landing-page-container" layout="column" layout-align="center center">
  <div>
  <div>
    <div>
    <div>
      <img src="assets/zonar_logo.svg">
    </div>
      <h3 class="driver-title animate__animated animate__fadeInLeftBig">Driver Game</h3>
    </div>
    <div>
      <img class="animated-truck" src="assets/icons8-truck.gif">
    </div>
    <div>
      <button mat-button (click)="navigateToComponent()">
        ENTER
    </button>
    </div>
  </div>
</div>
</div>

</div>
