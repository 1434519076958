import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatNativeDateModule } from "@angular/material/core";
import { ReactiveFormsModule } from "@angular/forms";
import { FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatRadioModule } from "@angular/material/radio";
import { DriverGameApiService } from "@app/services/driver-game-api.service";
import { GetDriverScoreParams } from "@app/core/models/driver-score.model";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-filter-table",
  standalone: true,
  imports: [
    CommonModule,
    MatButtonToggleModule,
    MatRadioModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    ReactiveFormsModule,
  ],
  templateUrl: "./filter-table.component.html",
  styleUrls: ["./filter-table.component.scss"],
})
export class FilterTableComponent implements OnInit {
  @Input() getHighScores: (searchParams: GetDriverScoreParams) => void;
  dte = new Date();
  @Output() selectDayOrWeek = new EventEmitter<string>();
  searchParams: GetDriverScoreParams = {
    startTime: "2023-04-26",
    endTime: "2023-05-03",
    companyId: "ecba6d94-7f4c-4408-ad7c-9beac51e0454",
  };
  constructor(readonly apiService: DriverGameApiService) {}

  ngOnInit(): void {
    // console.log(this.dte);
    return;
  }

  onChange(chosenTime): void {
    this.selectDayOrWeek.emit(chosenTime);
    // console.log(this.selectDayOrWeek);
    this.getHighScores(this.searchParams);
  }
}
