import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaviconsService } from '@zonar-ui/core';
import { environment } from 'src/environments/environment';
import { permissions } from 'src/environments/shared';
import { ZonarUiAnalyticsService } from '@zonar-ui/analytics';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ], providers: [
    FaviconsService,
    
    // TODO: Build out Auth0 application in dev, QA, and Prod tenants.
    // TODO: Onboard to Core API for your new application to be able to build permissions.

    // Symbols required for authentication
    {
      provide: 'appUrl', useValue: environment.appUrl
    },
    {provide: 'audience', useValue: environment.auth.audience},
    {provide: 'clientID', useValue: environment.auth.clientID},
    {provide: 'domain', useValue: environment.auth.domain},
    {provide: 'applicationId', useValue: environment.auth.applicationId},
    {provide: 'environment', useValue: environment.authEnv},
    {provide: 'region', useValue: environment.region},
    {provide: 'useRefreshTokens', useValue: environment.auth.useRefreshTokens},
    // Symbols required for permissions.
    {provide: 'defaultPermPrefix', useValue: permissions.defaultPermPrefix},
    {provide: 'defaultZonarRole', useValue: environment.auth.defaultZonarRole},
    {provide: 'routePermissions', useValue: permissions.routes},
    {provide: 'redirectUrl', useValue: permissions.defaultRedirectUrl},
    {
      provide: 'supportedLanguages',
      useValue: environment.i18n.supportedLanguages
    },
    { provide: 'defaultLanguage', useValue: environment.i18n.defaultLanguage },


    ZonarUiAnalyticsService,
  ]
})
export class CoreModule { }
