<div
  ngClass.gt-sm="app-container-desktop"
  ngClass.lt-md="app-container-mobile"
  fxLayout="column"
  fxFill
>
  <zui-sidenav (emitSelectedCompany)="setCurrentCompanyByFormSelection($event)">
    <div class="sidenav-container" fxFlex fxLayout="column">
      <zui-sidenav-header
        class="header-bar-sidenav"
        [title]="title"
      ></zui-sidenav-header>
      

      
      <div class="content-container" fxFlex>
        <router-outlet></router-outlet>
      </div>
    </div>
  </zui-sidenav>
</div>

