import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, of } from "rxjs";
import * as moment from "moment-timezone";
import { environment as env } from "@environments/environment";
import {
  DriverGameApiOperation,
  DriverScore,
  DriverScoreSortField,
  GetDriverScoreParams,
  SortDirection,
} from "@app/core/models/driver-score.model";
import { getLocalEndTime, getLocalStartTime } from "@app/shared/utilities";

@Injectable({
  providedIn: "root",
})
export class DriverGameApiService {
  constructor(private http: HttpClient) {}

  private _getData(url: string, params): Observable<any> {
    if (!params) {
      console.error("Driver Scores api request missing or malformed.");
      return of([]);
    }
    const headers = new HttpHeaders().append(
      "Content-Type",
      "application/json"
    );
    return this.http.post(url, params, { headers });
  }

  getDriverScores(params?: GetDriverScoreParams): Observable<DriverScore[]> {
    const url = env.apiBase.url;
    const newParams = {
      sortBy: "score",
      sortDir: "desc",
      endTime: getLocalEndTime(),
      startTime: getLocalStartTime(),
      ...params,
      operation: DriverGameApiOperation.DRIVER_SCORE,
      apiKey: env.apiBase.key,
    };
    return this._getData(url, newParams);
  }
}
