<div class="form-container">
  <mat-button-toggle-group
    (change)="onChange($event.value)"
    class="toggle-group"
    name="fontStyle"
    aria-label="Font Style"
  >
    <mat-button-toggle value="day">Yesterday's High Scores</mat-button-toggle>
    <mat-button-toggle checked value="week"
      >Last Week's High Scores</mat-button-toggle
    >
  </mat-button-toggle-group>
</div>
<img src="assets/bw_zonar.svg" />
